html {
    font-size: 1em;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
}

.app {
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #234094;
}

.header {
    background-color: #234094;
    padding: 2vw;
    color: white;
}

.page {
    background-color: white;
    height: 85vh;
}

.footer {
    background-color: #234094;
    padding: 1vw;
    color: white;
}